import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import Header from "../components/Header"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = () => {
  const { turtimmen, hero1, hero2 } = useStaticQuery(graphql`
    query {
      turtimmen: file(relativePath: { eq: "turtimmen.png" }) {
        childImageSharp {
          fixed(height: 128) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hero1: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero2: file(relativePath: { eq: "hero2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <Layout>
        <SEO title="Våra evenemang" />
        <Hero
          backgroundColor="#f1adac"
          backgroundImage={hero1.childImageSharp.fluid}
        >
          <Img fixed={turtimmen.childImageSharp.fixed} alt="Turtimmen" />
          <h2>Turtimmen</h2>
          <h3>Onsdag & Lördag i september</h3>
          <p>
            Står du i kassan när klockan ringer? En klockren tävling mee chans
            att vinna varor till ett värde 500 SEK.
          </p>
          <Link to="/turtimmen" type="button" className="cta">
            Läs mer
          </Link>
        </Hero>
        <Hero
          backgroundColor="#f2f2f2"
          //backgroundImage={hero2.childImageSharp.fluid}
        >
          <h2>Drömdagen</h2>
          <h3>2 - 13 oktober</h3>
          <br />
          <Img
            fluid={hero2.childImageSharp.fluid}
            alt="Drömdagen"
            style={{ borderRadius: "50%" }}
          />
          <p>
            Tävla och vinn 10.000 kr att shoppa för tillsammans med en vän! När
            du handlar för minst 300 kr i Axet och Västerports butiker får du en
            tävlingstalong.
          </p>
          <Link to="/dromdagen" type="button" className="cta">
            Läs mer
          </Link>
        </Hero>
      </Layout>
    </>
  )
}

export default IndexPage
